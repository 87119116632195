import { Auth } from "aws-amplify";
import Config from "../config";
import axios from "axios";

async function getToken() {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  return token;
}

async function getAuthHeader() {
  return { headers: { Authorization: `Bearer ${await getToken()}` } };
}

async function getPostInit(body) {
  return {
    method: "POST",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
    },
    body: JSON.stringify(body),
  };
}

async function getDeleteInit(body) {
  const init = {
    method: "DELETE",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
    },
  };
  if (body) {
    init.body = JSON.stringify(body);
  }
  return init;
}

async function getRepos() {
  const url = `${Config.FoveonicsAPI}/api/Repository/GetRepo`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getReposByClientId(clientId) {
  const url = `${Config.FoveonicsAPI}/api/Repository/GetReposByClientId`;
  const response = await axios.get(url, {
    params: {
      clientId: clientId,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response.data;
}

async function getInnerNodes(nodeId, count) {
  const url = `${Config.FoveonicsAPI}/api/Repository/GetInnerNodes/${nodeId}/${count}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getFileLIst(nodeId, indexTemplate) {
  const url = `${Config.FoveonicsAPI}/api/Repository/GetFileListByTemplateName/${nodeId}/${indexTemplate}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function updateFileName(renameFilesParams) {
  const init = await getPostInit(renameFilesParams);
  const url = `${Config.FoveonicsAPI}/api/Files/UpdateFileName`;
  return await fetch(url, init);
}

async function deleteFile(deleteFileIdsParams) {
  const init = await getDeleteInit(deleteFileIdsParams);
  const url = `${Config.FoveonicsAPI}/api/Files/DeleteFiles`;
  return await fetch(url, init);
}

async function getFileData(fileId, client) {
  const url = `${Config.FoveonicsAPI}/api/Workflow/GetFileData/${fileId}/${client}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function selectedFilesSendEmail(emailParams) {
  const init = await getPostInit(emailParams);
  const url = `${Config.FoveonicsAPI}/api/Email/SelectedFilesSendEmail`;
  return await fetch(url, init);
}

async function fillCliftonExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillCliftonExpungmentForm`;
  return await fetch(url, init);
}

async function fillCliftonJuvenileExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillCliftonJuvenileExpungmentForm`;
  return await fetch(url, init);
}

async function fillCliftonJuvenileFileReportsExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillCliftonJuvenileFileReportsExpungmentForm`;
  return await fetch(url, init);
}

async function fillGlenrockArrestExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillGlenRockArrestExpungmentForm`;
  return await fetch(url, init);
}

async function fillAberdeenArrestExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillAberdeenExpungmentForm`;
  return await fetch(url, init);
}

async function fillMedfordExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillMedfordExpungmentForm`;
  return await fetch(url, init);
}

async function fillEnglewoodExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillEnglewoodExpungmentForm`;
  return await fetch(url, init);
}

async function fillDemoExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillDemoExpungmentForm`;
  return await fetch(url, init);
}

async function fillEastamptonArrestReportExpungmentForm(body) {
  const init = await getPostInit(body);
  const url = `${Config.FoveonicsAPI}/api/Workflow/FillEastamptonArrestReportExpungmentForm`;
  return await fetch(url, init);
}

async function getTemplates() {
  const url = `${Config.FoveonicsAPI}/api/Templates/`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getTemplate(tableName) {
  const url = `${Config.FoveonicsAPI}/api/Templates/${tableName}/`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function searchTemplate(selectedTemplate, searchQueryString) {
  const url = `${Config.FoveonicsAPI}/api/Templates/${selectedTemplate}/search?${searchQueryString}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getTemplates2() {
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/GetTemplates/`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getRepositoriesByClient(clientId) {
  const url = `${Config.FoveonicsAPI}/api/Repository/GetRepositoriesByClient/${clientId}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getAllClients(repositoryId) {
  const url = `${Config.FoveonicsAPI}/api/Repository/GetAllClients/`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getTemplatesBasedOnRepositoryId(repositoryId) {
  const url = `${Config.FoveonicsAPI}/api/Templates/GetTemplatedBasedOnRepository/${repositoryId}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getRepositories() {
  const url = `${Config.FoveonicsAPI}/api/Repository/GetRepositories/`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function deleteTemplate(tableName) {
  const init = await getDeleteInit();
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/DeleteTemplate/${tableName}`;
  return await fetch(url, init);
}

async function getAllTemplateColumns(tableName) {
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/GetAllTemplatesColumns/${tableName}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function addIndexTemplateData(addNewTemplateParams) {
  const init = await getPostInit(addNewTemplateParams);
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/AddIndexTemplateData/`;
  const response = await fetch(url, init);
  return await response.json();
}

async function updateFieldData(rowData) {
  const init = await getPostInit(rowData);
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/UpdateFieldData`;
  return await fetch(url, init);
}

async function deleteColTemplate(deleteParams) {
  const init = await getDeleteInit(deleteParams);
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/DeleteColTemplate`;
  return await fetch(url, init);
}

async function addFieldData(addParams) {
  const init = await getPostInit(addParams);
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/AddFieldData/`;
  return await fetch(url, init);
}

async function signUp(userDetails) {
  const init = await getPostInit(userDetails);
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/SignUp`;
  return await fetch(url, init);
}

async function listGroups() {
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/ListGroups`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function updateGroups(userDetails) {
  const init = await getPostInit(userDetails);
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/UpdateGroups`;
  return await fetch(url, init);
}

async function getListValue(tableName) {
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/GeListValue/${tableName}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function getListValueField(tableName, fieldName) {
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/GetListValuesField/${tableName}/${fieldName}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function updateControlListData(rowData) {
  const init = await getPostInit(rowData);
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/UpdateControlListData`;
  return await fetch(url, init);
}

async function deleteListField(controlledListId) {
  const init = await getDeleteInit();
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/DeleteListField/${controlledListId}`;
  return await fetch(url, init);
}

async function addFieldListData(addParams) {
  const init = await getPostInit(addParams);
  const url = `${Config.FoveonicsAPI}/api/ManageIndexTemplate/AddFieldListData/`;
  const response = await fetch(url, init);
  return await response.json();
}

async function addRepoData(newRow) {
  const init = await getPostInit(newRow);
  const url = `${Config.FoveonicsAPI}/api/Repository/AddRepoData/`;
  const response = await fetch(url, init);
  return await response.json();
}

async function saveNewClient(clientName) {
  const init = await getPostInit(clientName);
  const url = `${Config.FoveonicsAPI}/api/Repository/AddClientData/`;
  const response = await fetch(url, init);
  return await response.json();
}

async function deleteRepository(repositoryId) {
  const init = await getDeleteInit();
  const url = `${Config.FoveonicsAPI}/api/Repository/DeleteRepository/${repositoryId}`;
  return await fetch(url, init);
}

async function updateRepoData(rowData) {
  const init = await getPostInit(rowData);
  const url = `${Config.FoveonicsAPI}/api/Repository/UpdateRepoData/`;
  return await fetch(url, init);
}

async function addFolder(jArrayData) {
  const init = await getPostInit(jArrayData);
  const url = `${Config.FoveonicsAPI}/api/Repository/AddFolder/`;
  const response = await fetch(url, init);
  return await response.text();
}

async function deleteFolder(nodeId) {
  const init = await getDeleteInit();
  const url = `${Config.FoveonicsAPI}/api/Repository/DeleteFolder/${nodeId}`;
  return await fetch(url, init);
}

async function moveOrCopyFiles(moveFileParameter) {
  const url = `${Config.FoveonicsAPI}/api/Files/MoveOrCopyFile`;
  const response = await axios.post(url, moveFileParameter, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response;
}

async function getPageCount(fileId) {
  const url = `${Config.FoveonicsAPI}/api/Files/GetPageCount`;
  const response = await axios.post(url, fileId, {
    params: {
      fileId: fileId,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response;
}

async function updateUserRoles(userId, roleIds, clientId, userEmail, ipAccess) {
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/UpdateUserRoles`;

  // Prepare the request payload
  const userDetails = {
    userId: userId,
    roleIds: roleIds, // This should be an array of role IDs (strings)
    clientId: clientId,
    userEmail: userEmail,
    ipAccess: ipAccess,
  };

  // Make the POST request with the payload, headers, and token
  const response = await axios.post(url, userDetails, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`, // Assuming you have an async getToken function
      "Access-Control-Allow-Origin": "*", // If needed for CORS
    },
  });

  return response; // Return the response to handle further if needed
}

async function getListRoles(clientId, email) {
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/GetListRoles`;
  const response = await axios.get(url, {
    params: {
      clientId: clientId,
      email: email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response.data;
}

async function getClientDetails() {
  // Define the URL for the API call
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/GetClientDetails`;

  try {
    // Make the API request using axios
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getToken()}`, // Use the token for authorization
        "Access-Control-Allow-Origin": "*", // Allow cross-origin
      },
    });

    // Return the API response
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error; // Handle or propagate the error as needed
  }
}

async function getUserDetailsByEmail(email) {
  // Define the URL for the API call
  const url = `${Config.FoveonicsAPI}/api/Authentication/GetUserDetailsByEmail`;

  try {
    // Make the API request using axios
    const response = await axios.get(url, {
      params: {
        email: email, // Pass the email parameter to the API call
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getToken()}`, // Use the token for authorization
        "Access-Control-Allow-Origin": "*", // Allow cross-origin
      },
    });

    // Return the API response
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error; // Handle or propagate the error as needed
  }
}

async function getUsersByClientId(clientId) {
  // Define the URL for the API call
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/GetUsersWithRolesByClientId`;
  try {
    // Make the API request using axios
    const response = await axios.get(url, {
      params: {
        clientId: clientId, // Pass the email parameter to the API call
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getToken()}`, // Use the token for authorization
        "Access-Control-Allow-Origin": "*", // Allow cross-origin
      },
    });

    // Return the API response
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error; // Handle or propagate the error as needed
  }
}

async function startOcr(templateId) {
  const url = `${Config.FoveonicsAPI}/api/Ocr/StartOcr`;
  const response = await axios.post(url, templateId, {
    params: {
      templateId: templateId,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response;
}

async function getInitializedOcrStatus(templateId) {
  const url = `${Config.FoveonicsAPI}/api/Ocr/GetInitializedOcrStatus`;
  const response = await axios.get(url, {
    params: {
      templateId: templateId,
    },
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response;
}

async function UpdateAutoOcrItem(templateId, autoOcr) {
  const url = `${Config.FoveonicsAPI}/api/Ocr/UpdateAutoOcrItem`;
  const response = await axios.put(
    url,
    {
      TemplateId: templateId,
      AutoOcr: autoOcr,
    },
    {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  return response;
}

async function getOcrTemplatesInfo() {
  const url = `${Config.FoveonicsAPI}/api/Ocr/GetOcrStatusWithTemplatesAndFileCount`;
  const response = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return await response;
}

async function insertOrUpdateUserProfile(data) {
  const url = `${Config.FoveonicsAPI}/api/Files/InsertOrUpdateUserProfile`;
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response;
}

async function getUserProfile(data) {
  const url = `${Config.FoveonicsAPI}/api/Templates/GetUserProfile`;
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response;
}

async function getUserProfileWithOutTemplate(data) {
  const url = `${Config.FoveonicsAPI}/api/Templates/GetUserProfileWithOutTemplate`;
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response;
}

async function listUsersWithoutGroups() {
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/ListUsersWithoutGroups`;
  const response = await fetch(url, await getAuthHeader());
  return await response.json();
}

async function listGroupsForUser2(username) {
  const init = await getPostInit({ username });
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/ListGroupsForUser2`;
  const response = await fetch(url, init);
  return await response.json();
}

async function listReposForUser(userEmail) {
  const init = await getPostInit({ userEmail });
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/ListReposForUser`;
  const response = await fetch(url, init);
  return await response.json();
}

async function updateRepoAccess(username, repos, userEmail) {
  const init = await getPostInit({ username, repos, userEmail });
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/UpdateRepoAccess`;
  return await fetch(url, init);
}

async function templateHasOcredFiles(templateId) {
  const url = `${Config.FoveonicsAPI}/api/OCR/TemplateHasOcredFiles/${templateId}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.text();
}

async function uploadFile(addFileParams) {
  const url = `${Config.FoveonicsAPI}/api/Files/AddAndUploadFiles`;
  const init = await getPostInit(addFileParams);
  const response = await fetch(url, init);
  return await response.text();
}

async function deleteUser(deleteUserParams) {
  const init = await getDeleteInit(deleteUserParams);
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/DeleteUsers`;
  return await fetch(url, init);
}

async function getGeoLoaction() {
  var config = {
    crossDomain: true,
  };
  const url = Config.Geolocation;
  const response = await axios.get(url, config, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  //console.log(response);
  return response.data.ip;
}

async function checkIpAddress(ipParams) {
  const url = `${Config.FoveonicsAPI}/api/Authentication/CheckIpAddress`;
  const init = await getPostInit(ipParams);
  const response = await fetch(url, init);
  return response;
}

async function saveAuditTrail(auditDetails) {
  const url = `${Config.FoveonicsAPI}/api/Authentication/SaveAuditTrail`;
  const init = await getPostInit(auditDetails);
  return await fetch(url, init);
}

async function getSavedRestrictedIps(userEmailAddress) {
  const url = `${Config.FoveonicsAPI}/api/ManageUsers/GetSavedRestrictedIps/${userEmailAddress}`;
  const response = await fetch(url, await getAuthHeader());
  return await response.text();
}

async function uploadFileChunk(addFileParams) {
  const url = `${Config.FoveonicsAPI}/api/Files/UploadChunks`;
  const response = await axios.post(url, addFileParams.chunk, {
    params: {
      chunkFileName: addFileParams.chunkFileName,
      count: addFileParams.count,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response;
}

async function uploadFileComplete(addFileParams) {
  const url = `${Config.FoveonicsAPI}/api/Files/UploadComplete`;
  const response = await axios.post(
    url,
    {},
    {
      params: {
        chunkFileName: addFileParams.chunkFileName,
        tableName: addFileParams.tableName,
        nodeId: addFileParams.nodeId,
        originalFileName: addFileParams.originalFileName,
        appendPrependOperation: addFileParams.appendPrependOperation,
        appendPrependFileId: addFileParams.appendPrependFileId,
        insertPageAfter: addFileParams.insertPageAfter,
        email: addFileParams.email,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  return response;
}

export {
  getRepos,
  getRepositories,
  getInnerNodes,
  updateFileName,
  getFileData,
  selectedFilesSendEmail,
  fillCliftonExpungmentForm,
  getTemplates,
  getTemplate,
  searchTemplate,
  getTemplates2,
  getAllTemplateColumns,
  getRepositoriesByClient,
  getAllClients,
  addIndexTemplateData,
  addFieldData,
  updateFieldData,
  deleteColTemplate,
  deleteTemplate,
  signUp,
  listGroupsForUser2,
  listReposForUser,
  listUsersWithoutGroups,
  listGroups,
  updateGroups,
  getListValue,
  getListValueField,
  updateControlListData,
  deleteListField,
  addFieldListData,
  addRepoData,
  saveNewClient,
  deleteRepository,
  updateRepoData,
  updateRepoAccess,
  templateHasOcredFiles,
  fillMedfordExpungmentForm,
  addFolder,
  deleteFolder,
  deleteFile,
  uploadFile,
  getTemplatesBasedOnRepositoryId,
  getFileLIst,
  getGeoLoaction,
  checkIpAddress,
  getSavedRestrictedIps,
  saveAuditTrail,
  moveOrCopyFiles,
  uploadFileChunk,
  uploadFileComplete,
  deleteUser,
  getPageCount,
  insertOrUpdateUserProfile,
  getUserProfile,
  getUserProfileWithOutTemplate,
  fillEnglewoodExpungmentForm,
  getOcrTemplatesInfo,
  startOcr,
  getInitializedOcrStatus,
  fillCliftonJuvenileExpungmentForm,
  fillCliftonJuvenileFileReportsExpungmentForm,
  fillGlenrockArrestExpungmentForm,
  fillAberdeenArrestExpungmentForm,
  fillDemoExpungmentForm,
  fillEastamptonArrestReportExpungmentForm,
  UpdateAutoOcrItem,
  getUserDetailsByEmail,
  getUsersByClientId,
  getClientDetails,
  getListRoles,
  updateUserRoles,
  getReposByClientId,
};

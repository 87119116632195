import React, { Component, Fragment } from "react";
import Popup from "reactjs-popup";
import {
  FormGroup,
  Row,
  Col,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import Upload from "./upload";
import { getPageCount } from "../../libs/API";

class AppendPrependPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      appendPrependValue: "",
      showInsertForm: false,
      pageCount: 0,
      insertTextBox: 0,
      showLoadingText: "",
    };
    // console.log("append prepend popup props", props);
  }

  async componentDidMount() {
    this.setState({ popupOpen: this.props.props.showAppendPrependPopup });
  }

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  };
  handleChange = async (e) => {
    e.preventDefault();
    let _showInsertForm = false;
    let _pageCount = 0;
    let targetValue = e.target.value;
    if (targetValue === "Insert") {
      this.setState({ showLoadingText: "...Loading page Count" });
      _showInsertForm = true;
      await getPageCount(this.props.props.fileIdArray[0].FileId).then((x) => {
        _pageCount = x.data;
      });
    }
    this.setState({
      showLoadingText: "",
      appendPrependValue: targetValue,
      showInsertForm: _showInsertForm,
      pageCount: _pageCount,
      ShowLoadingText: "",
    });
  };
  render() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h5>
                Insert Files :{this.props.props.filePath}
                {this.props.props.fileIdArray[0].FileName}
              </h5>
              <br />
              <Row>
                <Col sm={12}>
                  <FormGroup controlId="selectappendTemplates">
                    <FormControl
                      componentClass="select"
                      onChange={this.handleChange}
                      required
                    >
                      <option key={-1} value="">
                        Select Prepend/Insert/Append Option
                      </option>
                      <option key={0} value="Prepend">
                        Prepend
                      </option>
                      <option key={2} value="Insert">
                        Insert
                      </option>
                      <option key={1} value="Append">
                        Append
                      </option>
                    </FormControl>
                  </FormGroup>
                  {this.state.showInsertForm ? (
                    <div>
                      <FormGroup>
                        <ControlLabel>
                          Total page count - {this.state.pageCount} Insert after
                          page :
                        </ControlLabel>
                      </FormGroup>
                      <FormGroup>
                        <FormControl
                          id="insertTextBox"
                          onChange={(e) =>
                            this.setState({ insertTextBox: e.target.value })
                          }
                          placeholder="Page number"
                          className="input-box"
                          type="number"
                        />
                      </FormGroup>
                    </div>
                  ) : (
                    <div>{this.state.showLoadingText}</div>
                  )}
                </Col>
              </Row>
              <FormGroup>
                <Upload
                  appendPrependValue={this.state.appendPrependValue}
                  closePopup={this.closePopup}
                  appendPrependFileId={this.props.props.fileIdArray[0].FileId}
                  insertPageAfter={this.state.insertTextBox}
                  totalPageCount={this.state.pageCount}
                  prependAppendCall={true}
                  email={this.props.props.email}
                />
              </FormGroup>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }
}

export default AppendPrependPdf;
